require("bootstrap/dist/css/bootstrap.css");
require("../css/base.css");
require("../css/styles.css");
require("../css/fonts.css");

const MOBILE = { max: 576 };
const TABLET = { min: 577, max: 768 };
const SMALL_DESKTOP = { min: 769, max: 992 };
const DESKTOP = { min: 993, max: 1200 };
const LARGE_SCREEN = { min: 1201 };

var clickEvent = (function () {
    if ("touchstart" in document.documentElement === true) return "touchstart";
    else return "click";
})();

function positionOverlay(containerId, relativeToId, inlineStyles) {
    var underlyingElement = document.querySelector(relativeToId);
    var overlay = document.querySelector(containerId);
    var rect = underlyingElement.getBoundingClientRect();

    let bottom = 0;
    let left = 0;
    const screenWidth = window.innerWidth;
    if (screenWidth <= MOBILE.max) {
        bottom = inlineStyles.mobile.bottom ?? "0";
        left = inlineStyles.mobile.left;
    } else if (screenWidth <= TABLET.max && screenWidth >= TABLET.min) {
        bottom = inlineStyles.tablet.bottom ?? "0";
        left = inlineStyles.tablet.left;
    } else if (
        screenWidth <= SMALL_DESKTOP.max &&
        screenWidth >= SMALL_DESKTOP.min
    ) {
        bottom = inlineStyles.smallDesktop.bottom ?? "0";
        left = inlineStyles.smallDesktop.left;
    } else if (screenWidth <= DESKTOP.max && screenWidth >= DESKTOP.min) {
        bottom = inlineStyles.desktop.bottom ?? "0";
        left = inlineStyles.desktop.left;
    } else {
        bottom = inlineStyles.largeScreen.bottom ?? "0";
        left = inlineStyles.largeScreen.left;
    }

    // overlay.style.border = "1px solid red";

    overlay.style.position = "absolute";
    overlay.style.bottom = bottom;
    overlay.style.left = left;
    overlay.style.zIndex = 99;
}

document.addEventListener("readystatechange", () => {
    if (document.readyState === "complete") {
        sequences.forEach((sequence) =>
            positionOverlay(
                sequence.containerId,
                sequence.relativeToId,
                sequence.imageStyles
            )
        );
    }
});

const sequences = [
    {
        containerId: "#animation-container-1",
        relativeToId: "#top-section",
        imageCount: 367,
        imagePath: "../assets/images/animations/Bino/",
        imageName: "character_binocular_man",
        classToAdd: "animation-image-bino",
        imageStyles: {
            mobile: { bottom: "-1%", left: "10%" },
            tablet: { bottom: "-1%", left: "10%" },
            smallDesktop: { bottom: "-1%", left: "10%" },
            desktop: { bottom: "-2%", left: "75%" },
            largeScreen: { bottom: "-2%", left: "75%" },
        },
    },

    {
        containerId: "#animation-container-4",
        relativeToId: "#section-container",
        imageCount: 305,
        imagePath: "../assets/images/animations/FM/",
        imageName: "character_fisherman",
        classToAdd: "animation-image-fm",
        imageStyles: {
            mobile: { bottom: "-90px", left: "50%" },
            tablet: { bottom: "-90px", left: "50%" },
            smallDesktop: { bottom: "-90px", left: "50%" },
            desktop: { bottom: "-90px", left: "50%" },
            largeScreen: { bottom: "-90px", left: "50%" },
        },
    },
];

var player1, player2;
function onYouTubeIframeAPIReady() {
    const screenWidth = window.innerWidth;

    const videoId = screenWidth <= MOBILE.max ? "5WpBKpztxRc" : "nuQDZv5OJgQ";
    const videoId2 = "VeZh_yd1fww";
    player1 = new YT.Player("youtube-video-1", {
        videoId,
        host: "https://www.youtube-nocookie.com",
        playerVars: {
            playsinline: 1,
            controls: 0,
            enablejsapi: 1,
            iv_load_policy: 3,
            origin: window.location.host,
        },
        events: {
            onReady: onPlayerOneReady,
            onStateChange: onPlayerOneStateChange,
        },
    });

    player2 = new YT.Player("youtube-video-2", {
        videoId: videoId2,
        host: "https://www.youtube-nocookie.com",
        playerVars: {
            playsinline: 1,
            controls: 0,
            enablejsapi: 1,
            iv_load_policy: 3,
            origin: window.location.host,
        },
        events: {
            onReady: onPlayerTwoReady,
            onStateChange: onPlayerTwoStateChange,
        },
    });
}

function onPlayerOneStateChange(event) {
    if (event.data === 2 || event.data === 0) {
        const overlay = document.getElementById("yt-1-overlay");
        const icon = document.getElementById("yt-1-play-icon");
        overlay.classList.add("fade-in");
        overlay.classList.remove("fade-out");
        icon.classList.add("fade-in");
        icon.classList.remove("fade-out");
    }
}

function onPlayerOneReady(event) {
    const overlay = document.getElementById("yt-1-overlay");
    const icon = document.getElementById("yt-1-play-icon");
    icon.addEventListener(clickEvent, function () {
        const playerState = player1.getPlayerState();
        if (playerState !== 1) {
            overlay.classList.remove("fade-in");
            overlay.classList.add("fade-out");
            icon.classList.remove("fade-in");
            icon.classList.add("fade-out");
            player1.playVideo();
        } else {
            overlay.classList.add("fade-in");
            overlay.classList.remove("fade-out");
            icon.classList.add("fade-in");
            icon.classList.remove("fade-out");
            player1.pauseVideo();
        }
    });

    overlay.addEventListener(clickEvent, function () {
        const playerState = player1.getPlayerState();

        if (playerState !== 1) {
            overlay.classList.remove("fade-in");
            overlay.classList.add("fade-out");
            icon.classList.remove("fade-in");
            icon.classList.add("fade-out");
            player1.playVideo();
        } else {
            overlay.classList.add("fade-in");
            overlay.classList.remove("fade-out");
            icon.classList.add("fade-in");
            icon.classList.remove("fade-out");
            player1.pauseVideo();
        }
    });
}

function onPlayerTwoStateChange(event) {
    if (event.data === 2 || event.data === 0) {
        const yt2 = document.getElementById("yt-2-overlay");
        const icon = document.getElementById("yt-2-play-icon");
        yt2.classList.add("fade-in");
        yt2.classList.remove("fade-out");
        icon.classList.add("fade-in");
        icon.classList.remove("fade-out");
    }
}

function onPlayerTwoReady(event) {
    const overlay = document.getElementById("yt-2-overlay");
    const icon = document.getElementById("yt-2-play-icon");

    icon.addEventListener(clickEvent, function () {
        const playerState = player2.getPlayerState();
        if (playerState !== 1) {
            icon.classList.remove("fade-in");
            overlay.classList.remove("fade-in");

            icon.classList.add("fade-out");
            overlay.classList.add("fade-out");

            player2.playVideo();
        } else {
            icon.classList.add("fade-in");
            overlay.classList.add("fade-in");

            overlay.classList.remove("fade-out");
            icon.classList.remove("fade-out");

            player2.pauseVideo();
        }
    });

    overlay.addEventListener(clickEvent, function () {
        const playerState = player2.getPlayerState();
        if (playerState !== 1) {
            icon.classList.remove("fade-in");
            overlay.classList.remove("fade-in");

            icon.classList.add("fade-out");
            overlay.classList.add("fade-out");

            player2.playVideo();
        } else {
            icon.classList.add("fade-in");
            overlay.classList.add("fade-in");

            icon.classList.remove("fade-out");
            overlay.classList.remove("fade-out");
            player2.pauseVideo();
        }
    });
}

document.addEventListener("DOMContentLoaded", () => {
    sequences.forEach((sequence) => {
        addFirstImage(sequence);
        setupImageSequence(sequence);
    });

    function addFirstImage({ containerId, imagePath, imageName, classToAdd }) {
        const container = document.querySelector(containerId);
        const img = new Image();
        img.src = `${imagePath}${imageName}000.png`;
        img.classList.add(classToAdd);
        container.appendChild(img);
    }

    function setupImageSequence({
        containerId,
        relativeToId,
        imageCount,
        imagePath,
        imageName,
        classToAdd,
        imageStyles,
    }) {
        const container = document.querySelector(containerId);
        let allImagesLoaded = false;
        let currentImageIndex = 0;
        const images = [];

        function preloadImages() {
            for (let i = 0; i <= imageCount; i++) {
                const img = new Image();
                img.src = `${imagePath}${imageName}${i
                    .toString()
                    .padStart(3, "0")}.png`;

                img.classList.add(classToAdd);

                images.push(img);
            }
            allImagesLoaded = true;
        }

        function loadImage() {
            if (allImagesLoaded) {
                if (currentImageIndex > imageCount) {
                    setTimeout(() => (currentImageIndex = 0), 1000);
                } else {
                    container.innerHTML = "";
                    container.appendChild(images[currentImageIndex++]);
                }
            }
        }

        function animateImages() {
            preloadImages();
            loadImage();
            positionOverlay(containerId, relativeToId, imageStyles);
            setInterval(loadImage, 20);
        }

        const options = { root: null, rootMargin: "500px", threshold: 0 };
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animateImages();
                    observer.unobserve(container);
                }
            });
        }, options);

        observer.observe(container);
    }

    // Ensure OneTrust is fully loaded
    if (typeof OnetrustActiveGroups !== "undefined") {
        handleMarketingCookieConsent();
    }
});

window.onload = function () {
    var body = document.getElementsByTagName("body");
    body[0].style.removeProperty("background");

    var wrapper = document.getElementById("wrapper");
    if (wrapper) {
        wrapper.style.removeProperty("display");
    }
};

window.addEventListener("resize", function () {
    sequences.forEach((sequence) =>
        positionOverlay(
            sequence.containerId,
            sequence.relativeToId,
            sequence.imageStyles
        )
    );
});

function handleMarketingCookieConsent() {
    if (OnetrustActiveGroups.includes("C0004")) {
        var interval = setInterval(function () {
            if (window.YT) {
                clearInterval(interval);
                onYouTubeIframeAPIReady();
            }
        }, 500);
    }
}

window.addEventListener("OneTrustGroupsUpdated", (event) => {
    handleMarketingCookieConsent();
});
